import axios from 'axios'
import store from 'store'
import { router } from '../router';
import { Toast, Dialog } from 'vant';

function startLoading() {
    Toast.loading({
        forbidClick: true,
        duration: 0,
    });
}

function endLoading() {
    Toast.clear();
}
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 300000 // request timeout
})
// 请求拦截
service.interceptors.request.use(
    (config) => {
        var token = store.get('eToken');
        if (!config.noauth && !token) {
            location.href = '/';
        }
        //设置请求头
        if (!config.noauth) {
            config.headers.token = token;
        }

        config.showloading && startLoading();
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

//响应拦截.

service.interceptors.response.use(
    (response) => {
        let res = response.data;
        endLoading();
        if (res.code == 1) {
            return res;
        } else if (res.code == 401) {
            Toast.fail(res.msg);
            store.remove('eToken');
            location.href = '/';
        }else {
            Dialog({ message: res.msg });
            //Toast.fail(res.msg);
        }
        return res;
    },
    (error) => {
        endLoading()
        // 获取状态码
        const { status } = error.response;
        const { data } = error.response;
        Toast.fail(status);
        data&&Toast.fail(data.msg);
        if(status==401){
            store.remove('eToken');
            location.href = '/';
        }else{
            return Promise.reject(error)
        }
    }
)
export default service