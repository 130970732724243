<template>
    <div class="main edit">
        <my-header :title="edit_data.title?`基本信息`:'加载中...'"></my-header>
        <van-skeleton title :row="20" :loading="loading">
            <div class="editform info_edit">
                <van-form @submit="edit_save" validate-trigger="onSubmit">
                    <van-field v-model="edit_data.title" label="名称" :rules="[{ required: true }]" placeholder="请输入" />
                    <van-field v-model="edit_data.cname" label="别名" placeholder="请输入" />
                    <van-field v-model="edit_data.price" label="价格" placeholder="￥" />
                    <van-field v-model="edit_data.price_unit" label="单位" placeholder="苗" />
                    <van-field readonly is-link clickable label="分类" v-model="edit_data.type.name" placeholder="选择类别" @click="showPicker = true" />
                    <van-popup v-model:show="showPicker" round position="bottom" get-container="#app">
                        <van-picker :columns="conf.types" :default-index="edit_data.type.i" :columns-field-names="f" title="选择类别" show-toolbar @confirm="typeOnConfirm" @cancel="showPicker = false" />
                    </van-popup>
                    <van-field readonly is-link clickable label="瓣型" v-model="edit_data.petal.name" placeholder="请选择" @click="showPetal = true" />
                    <van-popup v-model:show="showPetal" round position="bottom" get-container="#app">
                        <van-picker :columns="conf.petals" :default-index="edit_data.petal.i" :columns-field-names="f" title="选择瓣型" show-toolbar @confirm="petalOnConfirm" @cancel="showPetal = false" />
                    </van-popup>
                    <van-field readonly is-link clickable label="唇瓣" v-model="edit_data.tongue.name" placeholder="请选择" @click="showTongue = true" />
                    <van-popup v-model:show="showTongue" round position="bottom" get-container="#app">
                        <van-picker :columns="conf.tongues" :default-index="edit_data.tongue.i" :columns-field-names="f" title="选择唇瓣" show-toolbar @confirm="tongueOnConfirm" @cancel="showTongue = false" />
                    </van-popup>
                    <van-field readonly is-link clickable label="捧瓣" v-model="edit_data.side.name" placeholder="请选择" @click="showSide = true" />
                    <van-popup v-model:show="showSide" round position="bottom" get-container="#app">
                        <van-picker :columns="conf.sides" :default-index="edit_data.side.i" :columns-field-names="f" title="选择捧瓣" show-toolbar @confirm="sideOnConfirm" @cancel="showSide = false" />
                    </van-popup>
                    <van-field readonly is-link clickable label="特色" v-model="edit_data.teseText" placeholder="请选择" @click="showType = true" />
                    <van-popup v-model:show="showType" round position="bottom" get-container="#app">
                        <van-checkbox-group v-model="edit_data.tese" @change="teseOnConfirm">
                            <van-cell-group>
                                <van-cell v-for="(item,index) in conf.teses" clickable :key="item.id" :title="`${item.name}`" @click="toggle(index)">
                                    <template #right-icon>
                                        <van-checkbox :name="item.id" ref="checkboxes_type" @click.stop />
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-checkbox-group>
                    </van-popup>
                    <van-field v-model="edit_data.rank" label="头衔" placeholder="请输入" />
                    <van-field v-model="edit_data.description" label="" rows="3" type="textarea" maxlength="255" placeholder="品种简介" show-word-limit />
                    <div class="padding">
                    <van-button round block type="success" native-type="submit" class="margin-bottom-large">提交更新</van-button>
                </div>
                </van-form>
            </div>
        </van-skeleton>
    </div>
</template>
<script>
import { Wiki } from "@/api/wiki.js";
import { Picker, Checkbox, CheckboxGroup } from 'vant';
export default {
    components: {
        [Wiki.name]: Wiki,
        [Picker.name]: Picker,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
    },
    data() {
        return {
            loading: true,
            showPicker: false,
            showPetal: false,
            showTongue: false,
            showSide: false,
            showType: false,
            edit_data: { id: this.$route.params.id },
            f: { text: 'name', value: 'id' },
            conf:this.$store.get('conf')
        };
    },
    created: function() { this.getDetail() },
    methods: {
        //获取详情数据
        getDetail() {
            Wiki.detail({ 'id': this.edit_data.id, 'showloading': true })
                .then(res => {
                    this.loading = false;
                    let c=this.conf;
                    if (res) {
                        this.edit_data.litpic_obj = res.data.litpic ? [{ url: res.data.litpic }] : [];
                        this.edit_data.title = res.data.title;
                        this.edit_data.list_price = res.data.list_price;
                        this.edit_data.photos = res.data.photos;
                        this.edit_data.cname = res.data.cname;
                        this.edit_data.price = res.data.price;
                        this.edit_data.price_unit = res.data.price_unit;
                        this.edit_data.pubprice = res.data.pubprice;
                        this.edit_data.inprice = res.data.inprice;
                        this.edit_data.rank = res.data.rank;
                        this.edit_data.description = res.data.description;
                        this.edit_data.tese = res.data.tese.map(o=>o*1);
                        this.edit_data.teseText = c.teses.filter(o=>this.edit_data.tese.includes(o.id)).map(o=>o.name).join(',');
                        this.edit_data.type = c.types.find((o,i)=>{o.i=i;return o.id==res.data.typeid;});
                        this.edit_data.petal = c.petals.find((o,i)=>{o.i=i;return o.id==res.data.petal;});
                        this.edit_data.tongue = c.tongues.find((o,i)=>{o.i=i;return o.id==res.data.tongue;});
                        this.edit_data.side = c.sides.find((o,i)=>{o.i=i;return o.id==res.data.side;});
                        this.edit_data.activeNollapse = [0];
                        this.edit_data.url = res.data.url;
                    }
                })

        },
        //选择品种分类
        typeOnConfirm(value) {
            this.showPicker = false;
            this.edit_data.type = value;
        },
        //选择品种瓣型
        petalOnConfirm(value) {
            this.showPetal = false;
            this.edit_data.petal = value;
        },
        //选择品种唇瓣
        tongueOnConfirm(value) {
            this.showTongue = false;
            this.edit_data.tongue = value;
        },
        //选择品种捧瓣
        sideOnConfirm(value) {
            this.showSide = false;
            this.edit_data.side = value;
        },
        //选择品种特色
        teseOnConfirm() {
            this.edit_data.teseText = this.conf.teses.filter(o=>this.edit_data.tese.includes(o.id)).map(o=>o.name).join(',');
        },
        //toggle
        toggle(index) {
            this.$refs.checkboxes_type[index].toggle();
        },
        //基本信息保存
        edit_save() {
            Wiki.edit({ 'id': this.edit_data.id, 'typeid': this.edit_data.type.id, 'title': this.edit_data.title, 'cname': this.edit_data.cname, 'price': this.edit_data.price, 'price_unit': this.edit_data.price_unit, 'petal': this.edit_data.petal.id, 'tongue': this.edit_data.tongue.id, 'side': this.edit_data.side.id, 'tese': this.edit_data.tese, 'rank': this.edit_data.rank, 'description': this.edit_data.description, })
                .then(res => {
                    if (res&&res.code==1) {
                        this.$base.goBack(this);
                    }
                })
        },

        //通过值获取索引
        getIndexByVal(arr, val, key) {
            var r = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i][key] == val) {
                    r = i;
                    break;
                }
            }
            return r;
        },
        //通过分类ID获取分类别名
        getCnameBytypeid(typeid) {
            let types = this.conf.types;
            for (let i in types) {
                if (typeid == types[i]['id']) {
                    return types[i]['name'];
                }
            }
            return false;
        },
    },
    computed: {

        //显示详细信息

    },
    watch: {}
};
</script>
<style lang="less">
</style>