<template>
    <div class="main edit">
        <my-header :title="`添加新品种`"></my-header>
        <div class="editform info_edit">
            <van-form @submit="edit_save" validate-trigger="onSubmit" v-if="conf">
                <van-field v-model="edit_data.title" label="名称" :rules="[{ required: true }]" placeholder="请输入" />
                <van-field v-model="edit_data.cname" label="别名" placeholder="请输入" />
                <van-field v-model="edit_data.price" label="价格" placeholder="￥" />
                <van-field v-model="edit_data.price_unit" label="单位" placeholder="苗" />
                <van-field readonly is-link clickable label="分类" v-model="edit_data.type.name" placeholder="选择类别" @click="showPicker = true" />
                <van-popup v-model:show="showPicker" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.types" :default-index="0" :columns-field-names="customFieldName" title="选择类别" show-toolbar @confirm="typeOnConfirm" @cancel="showPicker = false" />
                </van-popup>
                <van-field readonly is-link clickable label="瓣型" v-model="edit_data.petal.name" placeholder="请选择" @click="showPetal = true" />
                <van-popup v-model:show="showPetal" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.petals" :default-index="0" :columns-field-names="customFieldName" title="选择瓣型" show-toolbar @confirm="petalOnConfirm" @cancel="showPetal = false" />
                </van-popup>
                <van-field readonly is-link clickable label="唇瓣" v-model="edit_data.tongue.name" placeholder="请选择" @click="showTongue = true" />
                <van-popup v-model:show="showTongue" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.tongues" :default-index="0" :columns-field-names="customFieldName" title="选择唇瓣" show-toolbar @confirm="tongueOnConfirm" @cancel="showTongue = false" />
                </van-popup>
                <van-field readonly is-link clickable label="捧瓣" v-model="edit_data.side.name" placeholder="请选择" @click="showSide = true" />
                <van-popup v-model:show="showSide" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.sides" :default-index="0" :columns-field-names="customFieldName" title="选择捧瓣" show-toolbar @confirm="sideOnConfirm" @cancel="showSide = false" />
                </van-popup>
                <van-field readonly is-link clickable label="特色" v-model="edit_data.teseText" placeholder="请选择" @click="showType = true" />
                <van-popup v-model:show="showType" round position="bottom" get-container="#app">
                    <van-checkbox-group v-model="edit_data.tese" @change="teseOnConfirm">
                        <van-cell-group>
                            <van-cell v-for="(item,index) in conf.teses" clickable :key="item.id" :title="`${item.name}`" @click="toggle(index)">
                                <template #right-icon>
                                    <van-checkbox :name="item.id" ref="checkboxes_type" @click.stop />
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-checkbox-group>
                </van-popup>
                <div class="padding margin-large">
                    <van-button round block type="success" native-type="submit">确认添加</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
import { Wiki } from "@/api/wiki.js";
import { Picker, Checkbox, CheckboxGroup } from 'vant';
export default {
    components: {
        [Wiki.name]: Wiki,
        [Picker.name]: Picker,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
    },
    data() {
        return {
            loading: true,
            showPicker: false,
            showPetal: false,
            showTongue: false,
            showSide: false,
            showType: false,
            edit_data: {
                title: this.$route.params.title,
                typeid: this.$route.params.typeid?this.$route.params.typeid:0
            },
            customFieldName: { text: 'name', value: 'id' },
            conf: this.$store.get('conf')
        };
    },
    created: function() {
        this.edit_data.type = this.conf.types.find((o,i)=>{o.i=i;return o.id==this.edit_data.typeid;});
        this.edit_data.teseText='';
        this.edit_data.petal = this.conf.petals[0];
        this.edit_data.tongue = this.conf.tongues[0];
        this.edit_data.side = this.conf.sides[0];
    },
    computed: function() {

    },
    methods: {
        //选择品种分类
        typeOnConfirm(value) {
            this.showPicker = false;
            this.edit_data.type = value;
        },
        //选择品种瓣型
        petalOnConfirm(value) {
            this.showPetal = false;
            this.edit_data.petal = value;
        },
        //选择品种唇瓣
        tongueOnConfirm(value) {
            this.showTongue = false;
            this.edit_data.tongue = value;
        },
        //选择品种捧瓣
        sideOnConfirm(value) {
            this.showSide = false;
            this.edit_data.side = value;
        },
        //选择品种特色
        teseOnConfirm() {
            this.edit_data.teseText = this.conf.teses.filter(o => this.edit_data.tese.includes(o.id)).map(o => o.name).join(',');
        },
        //toggle
        toggle(index) {
            this.$refs.checkboxes_type[index].toggle();
        },
        //基本信息保存
        edit_save() {
            Wiki.addsave({ 'typeid': this.edit_data.type.id, 'title': this.edit_data['title'], 'cname': this.edit_data['cname'], 'price': this.edit_data['price'], 'price_unit': this.edit_data['price_unit'], 'petal': this.edit_data.petal.id, 'tongue': this.edit_data.tongue.id, 'side': this.edit_data.side.id, 'tese': this.edit_data.tese, })
                .then(res => {
                    if (res) {
                        this.$toast.success(res.msg);
                        this.$base.goBack(this);
                    }
                })
        },
    },
    computed: {

        //显示详细信息

    },
    watch: {}
};
</script>
<style lang="less">
</style>