<template>
    <div class="main index">
        <my-header title="品种检索" :leftArrow="false"></my-header>
        <form action="" class="search_form">
            <van-search v-model="search.keyword" ref="keyword" @click-left-icon="search.keyword=' ';" shape="round" clear-trigger="always" autofocus background="#eee" placeholder="输入关键词、分类名称、价格..." />
        </form>
        <div class="page_search">
            <div class="search_list">
                <van-pull-refresh v-model="search.refreshing" @refresh="onRefresh">
                    <van-divider v-if="search.count>0">检索到<b>{{search.count}}</b>个相关品种</van-divider>
                    <van-divider v-else-if="search.word&&search.count==0">
                        <van-button icon="plus" type="success" size="small" @click="$router.push({name:'add',params:{ title:search.word, typeid:search.typeid}})">添加该品种</van-button>
                    </van-divider>
                    <van-divider v-else-if="search.finished"></van-divider>
                    <van-empty v-if="search.count==-1&&search.keyword==''&&search.finished" image="search" />
                    <van-list v-model:loading="search.loading" :finished="search.finished" :finished-text="search.count>0?'没有更多了':''" :error.sync="search.error" error-text="请求失败，点击重新加载" offset="100" @load="onLoad">
                        <van-swipe-cell v-for="item in search.list" :key="item.id" :class="['item',{'isok':item.inprice>0&&item.price>0}]">
                            <van-cell :border=false @click="$router.push({ path: '/wiki/detail/' + item.id })">
                                <van-badge :class="['litpic_box',{'nophotos':item.photos.length==0}]" :content="item.photos.length" :max="10">
                                    <van-image v-if="item.thumb" width="2rem" height="2rem" fit="cover" lazy-load :src="item.thumb">
                                        <template v-slot:loading>
                                            <van-loading type="spinner" size="20" /> </template>
                                    </van-image>
                                    <van-icon v-else class="nolitpic" name="photo" />
                                </van-badge>
                                <span class="title"><i>[{{conf.types.find(i=>i.id==item.typeid).name}}]</i>{{item.title}}<em v-if="item.cname">({{item.cname}})</em>
                                </span>
                                <span :class="['outprice','fr',{'transparent':item.price==0}]">￥<b>{{item.price}}</b>/{{item.price_unit}}</span>
                                <span :class="['inprice','fr',{'noinprice':item.inprice<=0 && item.pubprice>0}]"><i v-if="item.inprice>0"><em>￥</em><b>{{item.inprice}}</b></i><i v-if="item.pubprice>0"><em>￥</em><b>{{item.pubprice}}</b></i><i v-if="item.pubprice==0&&item.inprice==0">询价</i><i v-if="item.supplier">{{item.supplier.substr(0, 2)}}</i></span>
                            </van-cell>
                        </van-swipe-cell>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>
<script>
import { Wiki } from "@/api/wiki.js";

import { Search, } from 'vant';

export default {
    components: {
        [Wiki.name]: Wiki,
        [Search.name]: Search,
    },
    name: 'search',
    data() {
        return {
            search: {
                keyword: '',
                word: '',
                count: -1,
                list: [],
                finished: true,
                loading: false,
                refreshing: false,
                error: false,
                pagesize: 15,
                page: 1,
            },
            conf: this.$store.get('conf')
        };
    },
    created: function() {
        this.$emit('login');
    },
    methods: {
        //检索
        onSearch(keyword, page) {
            if (page == 1) {
                this.search.list = [];
                this.search.count = -1;
                //this.$refs.keyword.blur();
            }
            let keyword_old = keyword;
            keyword = keyword.replace(/(^\s*)/g, "");
            let price_min = 0;
            let price_max = 0;
            let typeid = 0;
            let word = '';


            let reg = /^([\u4e00-\u9fa5])\s+(\d*)$/;
            let reg1 = /^([\u4e00-\u9fa5])+$/;
            let reg2 = /^([\u4e00-\u9fa5])\s+([\u4e00-\u9fa5]+)$/;
            let reg3 = /^(\d*)+$/; //100，最小100
            let reg4 = /^([\u4e00-\u9fa5])\s+(\d*)\s+$/;
            let reg5 = /^(\d*)+\s+$/; //100空格，最大100
            if (reg.test(keyword)) {
                let $arr = keyword.match(reg);
                let typename = $arr[1];
                price_min = $arr[2] ? $arr[2] : 0;
                try{typeid = this.conf.ctypes.find(i=>i.name==typename).id}catch(e){};
                word = typeid > 0 ? '' : typename;
            } else if (reg4.test(keyword)) {
                let $arr = keyword.match(reg4);
                let typename = $arr[1];
                price_max = $arr[2] ? $arr[2] : 0;
                try{typeid = this.conf.ctypes.find(i=>i.name==typename).id}catch(e){};
                word = typeid > 0 ? '' : typename;
            } else if (reg2.test(keyword.trim())) {
                let $arr = keyword.match(reg2);
                let typename = $arr[1];
                try{typeid = this.conf.ctypes.find(i=>i.name==typename).id}catch(e){};
                word = typeid > 0 ? $arr[2] : keyword;
            } else if (reg3.test(keyword)) {
                let $arr = keyword.match(reg3);
                word = '';
                price_min = $arr[1] ? $arr[1] : 0;
            } else if (reg5.test(keyword)) {
                let $arr = keyword.match(reg5);
                word = '';
                price_max = $arr[1] ? $arr[1] : 0;
            } else if (reg1.test(keyword.trim())) {
                word = keyword.trim();
            } else {
                word = keyword.trim();
            }

            this.search.page = page;
            this.search.word = word;
            this.search.typeid = typeid;
            this.search.loading = true;
            this.search.finished = false;
            this.search.error = false;

            Wiki.search({ 'keyword': word, 'typeid': typeid, 'price_min': price_min, 'price_max': price_max, 'pagesize': this.search.pagesize, 'page': page })
                .then(res => {
                    if (res && res.code == 1) {
                        this.search.loading = false;
                        if (this.search.refreshing) {
                            this.search_init();
                        }

                        if (keyword_old != this.search.keyword) {
                            this.search_init();
                            return false;
                        }

                        if (res.data.data.length > 0 && page > 1) {
                            this.search.list = this.search.list.concat(res.data.data);
                        } else {
                            this.search.list = res.data.data;
                        }
                        this.search.count = res.data.total;
                        if (this.search.list.length >= this.search.count) {
                            this.search.finished = true;
                        } else {
                            this.search.finished = false;
                        }
                        this.search.page++;
                    }
                }).catch(() => {
                    this.search_init();
                });
        },
        //搜索复原
        search_init() {
            this.search.list = [];
            this.search.count = -1;
            this.search.finished = true;
            this.search.refreshing = false;
        },
        //检索下拉刷新
        onRefresh() {
            this.search.finished = false;
            this.search.refreshing = false;
            this.search.page = 1;
            this.onSearch(this.search.keyword, this.search.page);
        },
        //检索上拉加载
        onLoad() {
            this.search.loading = true;
            this.onSearch(this.search.keyword, this.search.page);
        },
        //通过分类名称获取分类ID
        getTypeidByname(typename) {
            let ctypes = this.conf.ctypes;
            for (let i in ctypes) {
                if (typename == ctypes[i]) {
                    return i;
                }
            }
        },
    },
    mounted() {},
    computed: {
        keyword() {
            return this.search.keyword
        }
    },
    watch: {
        keyword(curVal) {
            document.body.scrollIntoView({ behavior: 'smooth' });
            this.search.page = 1;
            if (curVal == '') {
                return this.search_init();
            }
            // 实现input连续输入，只发一次请求
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.search.list = [];
                this.search.count = -1;
                this.onSearch(curVal, 1);
            }, 700);
        }
    }
};
</script>
<style lang="less">
</style>