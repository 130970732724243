import request from '@/untils/request'
import QS from 'qs'
import base from '@/untils/base';
/**
 * post方法，对应post请求
 * @desc注册请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const User = {

    login: function(data) {
        return request({
            url: `${base.url}/api.user/login`,
            method: 'post',
            data: QS.stringify(data),
            showloading: true,
            noauth: true,
        })
    },

    info: function(data) {
        return request({
            url: `${base.url}/api.user/userinfo?t=`+new Date().getTime(),
            method: 'post',
            data: QS.stringify(data),
        })
    },

}