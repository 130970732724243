<template>
    <header :class="class">
        <van-nav-bar :title="title" :left-arrow="leftArrow" transition="fade" placeholder :border="false" @click-right="navshow=true" @click-left="goBack">
            <template #right>
                <van-icon name="weapp-nav" size="20" />
            </template>
        </van-nav-bar>
    </header>
    <van-popup v-model:show="navshow" position="top" closeable :style="{ height: '100%',width: '100%' }" teleport="body">
        <div class="mainnav padding-top-large margin-top-large">
            <van-cell-group>
                <van-cell :title="'hello，'+userinfo.nickname+'！'" :value="welcome" />
            </van-cell-group>
            <van-cell-group title="通用">
                <van-cell title="询价表" is-link icon="font" />
                <van-cell title="添加品种" is-link @click="goTo('/wiki/add')" icon="add" />
                <van-cell title="回收站" is-link @click="goTo('/wiki/recycle')" icon="delete" />
                <van-cell title="更新缓存" @click="clear()" icon="delete" />
            </van-cell-group>
            <van-cell-group>
                <van-cell title="版本" value="v3(top5.1|vue3|vant3)" />
                <van-cell>
                    <template #right-icon>
                        <van-button type="danger" size="small" block plain hairline @click="logout">退出登录</van-button>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
    </van-popup>
</template>
<script>

export default {
    components: {
    },
    props: {
        title: {
            default: '兰百科'
        },
        class: {},
        leftArrow: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            welcome: '加油！',
            userinfo: this.$store.get('userinfo'),
            conf:this.$store.get('conf'),
            navshow: false
        };
    },
    created: function() {
        let date = new Date();
        this.welcome=date.getFullYear()+this.welcome;
    },
    methods: {
        //登出
        logout(e) {
            this.$store.remove('eToken');
            location.href = '/';
        },
        //更新缓存
        clear() {
            this.$store.remove('conf');
            location.href = '/';
        },
        //跳转
        goTo: function(path) {
            this.navshow=false;
            if (path) {
                this.$router.push(path);
            }
        },
        //返回
        goBack: function() {
            if (this.routerName) {
                this.$router.replace({
                    name: this.routerName
                });
            } else {
                this.$router.go(-1);
            }
        }
    }
}
</script>
<style>
.van-dialog {
    top: 30%
}
</style>